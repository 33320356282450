import './styles/styles.scss';

import { Hide } from '@chakra-ui/media-query';
import { Box } from '@chakra-ui/react';
import React from 'react';

import NinetyEigthPercent from '../../../static/images/aboutv2/98percent.svg';
import AboutHeroDesktop from '../../../static/images/aboutv2/about-desktop-hero.jpg';
import AboutHeroMobile from '../../../static/images/aboutv2/about-mobile-hero.jpg';
import AwardsRatingsMiniHeroDesktop from '../../../static/images/aboutv2/awards-ratings-mini-desktop.jpg';
import AwardsRatingsMiniHeroMobile from '../../../static/images/aboutv2/awards-ratings-mini-mobile.jpg';
import CommunityInvolvementMiniHeroDesktop from '../../../static/images/aboutv2/community-involvement-mini-desktop.jpg';
import CommunityInvolvementMiniHeroMobile from '../../../static/images/aboutv2/community-involvement-mini-mobile.jpg';
import ExtrasMiniHeroDesktop from '../../../static/images/aboutv2/extras-mini-desktop.jpg';
import ExtrasMiniHeroMobile from '../../../static/images/aboutv2/extras-mini-mobile.jpg';
import FinancialStrengthMiniHeroDesktop from '../../../static/images/aboutv2/financial-strength-mini-desktop.jpg';
import FinancialStrengthMiniHeroMobile from '../../../static/images/aboutv2/financial-strength-mini-mobile.jpg';
import LeadershipMiniHeroDesktop from '../../../static/images/aboutv2/leadership-mini-desktop.jpg';
import LeadershipMiniHeroMobile from '../../../static/images/aboutv2/leadership-mini-mobile.jpg';
import LiMiniHeroDesktop from '../../../static/images/aboutv2/li-mini-desktop.jpg';
import LiMiniHeroMobile from '../../../static/images/aboutv2/li-mini-mobile.jpg';
import Salesman from '../../../static/images/aboutv2/Salesman.png';
import WoodmenLifeMiniHeroDesktop from '../../../static/images/aboutv2/woodmenlife-mini-desktop.jpg';
import WoodmenLifeMiniHeroMobile from '../../../static/images/aboutv2/woodmenlife-mini-mobile.jpg';
import BreadcrumbAdapter from '../../components/BreadcrumbAdapter';
import Layout from '../../components/Layout';
import PageIdentifier from '../../components/PageIdentifier';
import { WLButton, WLH1V2, WLH2V2, WLLink } from '../../design-library';
import { WLContainer } from '../../design-library';

const AboutPage = () => {
  return (
    <Layout
      seo={{
        title: 'Our Mission',
        description: `Uniting hardworking Americans to secure their financial future while strengthening our communities and country.`,
        slug: '/about',
        keywords: `About`,
      }}
      currentPath={'/about'}
    >
      <WLContainer
        width="EXTRA_WIDE"
        align="start"
        paddingX={{ base: 4, lg: 8 }}
        paddingTop={{ base: 2, lg: 2 }}
        paddingBottom={{ base: 3, lg: 3 }}
        justifyContent="center"
      >
        <BreadcrumbAdapter
          currentPage="About"
          parent={{
            breadcrumbTitle: 'Home',
            slug: '/',
          }}
        />
      </WLContainer>
      <>
        <WLContainer as="section" width="EXTRA_WIDE" className="hero">
          <picture>
            <source srcSet={AboutHeroDesktop} media="(min-width: 768px)" />
            <img
              width="100%"
              src={AboutHeroMobile}
              alt="A mother and two daughters smiling while eating ice cream"
            />
          </picture>

          <div className={'copy'}>
            <WLH1V2>
              <span className={'overline'}>About WoodmenLife</span> Learn What
              Sets Us Apart
            </WLH1V2>
            <p>
              Founded in 1890 as a not-for-profit life insurance company, we
              reinvest our profits back into our mission, supporting members
              <sup>1</sup> and communities rather than making money for
              shareholders.
            </p>
          </div>
        </WLContainer>
        <WLContainer width="EXTRA_WIDE" spacing={{ base: 0, lg: 1 }}>
          <WLContainer as="section" width="EXTRA_WIDE" className="hero">
            <a href="/insurance/life-insurance/">
              <picture>
                <source srcSet={LiMiniHeroDesktop} media="(min-width: 768px)" />
                <img
                  width="100%"
                  src={LiMiniHeroMobile}
                  alt="A father and son playing basketball"
                />
              </picture>
              <div className={'copy'}>
                <WLH2V2>
                  <span className={'overline'}>
                    Our Products{' '}
                    <svg
                      className="triangle"
                      width="7.113"
                      height="8.395"
                      viewBox="0 0 7.113 8.395"
                      role="presentation"
                      aria-hidden="true"
                      tabIndex={-1}
                    >
                      <path
                        className="triangle-path"
                        d="M4.152,1.357a1,1,0,0,1,1.7,0l3.2,5.113A1,1,0,0,1,8.2,8H1.8A1,1,0,0,1,.956,6.47Z"
                        transform="translate(8 -0.803) rotate(90)"
                      ></path>
                    </svg>
                  </span>
                  Explore What We Have to Offer
                </WLH2V2>
                <p>
                  We offer high-quality financial products, including life
                  insurance, retirement plans and annuities, to help meet your
                  needs.
                </p>
              </div>
            </a>
          </WLContainer>
          <WLContainer as="section" width="EXTRA_WIDE" className="hero">
            <a href="/extras/">
              <picture>
                <source
                  srcSet={ExtrasMiniHeroDesktop}
                  media="(min-width: 768px)"
                />
                <img
                  width="100%"
                  src={ExtrasMiniHeroMobile}
                  alt="A family of three laughing at a carnival"
                />
              </picture>
              <div className={'copy copy-reverse'}>
                <WLH2V2>
                  <span className={'overline'}>
                    Extras{' '}
                    <svg
                      className="triangle"
                      width="7.113"
                      height="8.395"
                      viewBox="0 0 7.113 8.395"
                      role="presentation"
                      aria-hidden="true"
                      tabIndex={-1}
                    >
                      <path
                        className="triangle-path"
                        d="M4.152,1.357a1,1,0,0,1,1.7,0l3.2,5.113A1,1,0,0,1,8.2,8H1.8A1,1,0,0,1,.956,6.47Z"
                        transform="translate(8 -0.803) rotate(90)"
                      ></path>
                    </svg>
                  </span>{' '}
                  Get More Out of Life Now
                </WLH2V2>
                <p>
                  We care about you and your family. Our members have access to
                  discounts, scholarships and other extras<sup>2</sup> to help
                  them at every stage of life.
                </p>
              </div>
            </a>
          </WLContainer>
          <WLContainer as="section" width="EXTRA_WIDE" className="hero">
            <a href="/about/financial-strength/">
              <picture>
                <source
                  srcSet={FinancialStrengthMiniHeroDesktop}
                  media="(min-width: 768px)"
                />
                <img
                  width="100%"
                  src={FinancialStrengthMiniHeroMobile}
                  alt="A mother and son at a volunteer event"
                />
              </picture>
              <div className={'copy'}>
                <WLH2V2>
                  <span className={'overline'}>
                    Financial Strength{' '}
                    <svg
                      className="triangle"
                      width="7.113"
                      height="8.395"
                      viewBox="0 0 7.113 8.395"
                      role="presentation"
                      aria-hidden="true"
                      tabIndex={-1}
                    >
                      <path
                        className="triangle-path"
                        d="M4.152,1.357a1,1,0,0,1,1.7,0l3.2,5.113A1,1,0,0,1,8.2,8H1.8A1,1,0,0,1,.956,6.47Z"
                        transform="translate(8 -0.803) rotate(90)"
                      ></path>
                    </svg>
                  </span>{' '}
                  Building Our Tomorrow
                </WLH2V2>
                <p>
                  When it comes to financial health, you don’t have to take our
                  word for it. AM Best — an independent rating company
                  nationally recognized for its objective reviews — awarded
                  WoodmenLife an A+ Superior rating<sup>3</sup>, their second
                  highest out of 13. This is a rating we’ve been proud to
                  receive for 48 years.
                </p>
              </div>
            </a>
          </WLContainer>
          <WLContainer
            as="section"
            width="EXTRA_WIDE"
            className="hero feature"
            backgroundColor={'wl-blue.sky'}
          >
            <img src={Salesman} alt="A Woodmenlife Sales Representative" />
            <WLH2V2 className="featureHeadline">Ready to Connect?</WLH2V2>
            <div style={{ textAlign: 'left' }}>
              <WLButton colorScheme="blue" href={'/getstarted/'}>
                Get Started
              </WLButton>
            </div>
          </WLContainer>
          <WLContainer
            as="section"
            width="EXTRA_WIDE"
            className="hero feature"
            background={`linear-gradient(180deg, rgba(1,31,60,1) 0%, rgba(0,51,102,1) 100%);`}
            color="white"
          >
            <img src={NinetyEigthPercent} alt={'98% logo'} />
            <WLH2V2 className="featureHeadline" style={{ color: 'white ' }}>
              <Hide below="768px">98% </Hide>Satisfaction Rating
            </WLH2V2>
            <div>
              <p>
                Ninety-eight percent of WoodmenLife members say the service they
                receive from their Representative at the time of the sale is
                “extremely” or “very” helpful.
              </p>
              <br />
              <p style={{ fontSize: '12px' }}>
                <sup>*</sup>2024 LIMRA Customer Assurance Program survey
              </p>
            </div>
          </WLContainer>
          <WLContainer as="section" width="EXTRA_WIDE" className="hero">
            <a href="/about/corporate-giving">
              <picture>
                <source
                  srcSet={CommunityInvolvementMiniHeroDesktop}
                  media="(min-width: 768px)"
                />
                <img
                  width="100%"
                  src={CommunityInvolvementMiniHeroMobile}
                  alt="Two young men participating in community cleanup"
                />
              </picture>
              <div className={'copy copy-reverse'}>
                <WLH2V2>
                  <span className={'overline'}>
                    Community involvement{' '}
                    <svg
                      className="triangle"
                      width="7.113"
                      height="8.395"
                      viewBox="0 0 7.113 8.395"
                      role="presentation"
                      aria-hidden="true"
                      tabIndex={-1}
                    >
                      <path
                        className="triangle-path"
                        d="M4.152,1.357a1,1,0,0,1,1.7,0l3.2,5.113A1,1,0,0,1,8.2,8H1.8A1,1,0,0,1,.956,6.47Z"
                        transform="translate(8 -0.803) rotate(90)"
                      ></path>
                    </svg>
                  </span>{' '}
                  Making a Difference Together
                </WLH2V2>
                <p>
                  We’re dedicated to supporting our communities through local
                  initiatives and volunteering. Through our National Community
                  Focus, we fight hunger by partnering with local organizations
                  to help ensure everyone has access to the food they need.
                </p>
              </div>
            </a>
          </WLContainer>
          <WLContainer as="section" width="EXTRA_WIDE" className="hero">
            <a href="/about/history/">
              <picture>
                <source
                  srcSet={WoodmenLifeMiniHeroDesktop}
                  media="(min-width: 768px)"
                />
                <img
                  width="100%"
                  src={WoodmenLifeMiniHeroMobile}
                  alt="The Woodmen Tower"
                />
              </picture>
              <div className={'copy'}>
                <WLH2V2>
                  <span className={'overline'}>
                    History{' '}
                    <svg
                      className="triangle"
                      width="7.113"
                      height="8.395"
                      viewBox="0 0 7.113 8.395"
                      role="presentation"
                      aria-hidden="true"
                      tabIndex={-1}
                    >
                      <path
                        className="triangle-path"
                        d="M4.152,1.357a1,1,0,0,1,1.7,0l3.2,5.113A1,1,0,0,1,8.2,8H1.8A1,1,0,0,1,.956,6.47Z"
                        transform="translate(8 -0.803) rotate(90)"
                      ></path>
                    </svg>
                  </span>{' '}
                  WoodmenLife Through The Years
                </WLH2V2>
                <p>
                  WoodmenLife was founded in Omaha in 1890, and we’re still
                  proud to call it home. We have continuously supported
                  families’ financial goals and community growth across America
                  through dedicated service.
                </p>
              </div>
            </a>
          </WLContainer>
          <WLContainer as="section" width="EXTRA_WIDE" className="hero">
            <a href="/about/awards-and-ratings/">
              <picture>
                <source
                  srcSet={AwardsRatingsMiniHeroDesktop}
                  media="(min-width: 768px)"
                />
                <img
                  width="100%"
                  src={AwardsRatingsMiniHeroMobile}
                  alt="A family walking through the prairie on a warm day"
                />
              </picture>
              <div className={'copy copy-reverse'}>
                <WLH2V2>
                  <span className={'overline'}>
                    Awards & Ratings{' '}
                    <svg
                      className="triangle"
                      width="7.113"
                      height="8.395"
                      viewBox="0 0 7.113 8.395"
                      role="presentation"
                      aria-hidden="true"
                      tabIndex={-1}
                    >
                      <path
                        className="triangle-path"
                        d="M4.152,1.357a1,1,0,0,1,1.7,0l3.2,5.113A1,1,0,0,1,8.2,8H1.8A1,1,0,0,1,.956,6.47Z"
                        transform="translate(8 -0.803) rotate(90)"
                      ></path>
                    </svg>
                  </span>{' '}
                  Setting the Standard
                </WLH2V2>
                <p>
                  WoodmenLife has received numerous ratings and awards that
                  reflect our commitment to excellence and service.
                </p>
              </div>
            </a>
          </WLContainer>
          <WLContainer as="section" width="EXTRA_WIDE" className="hero">
            <a href="/about/leadership/">
              <picture>
                <source
                  srcSet={LeadershipMiniHeroDesktop}
                  media="(min-width: 768px)"
                />
                <img
                  width="100%"
                  src={LeadershipMiniHeroMobile}
                  alt="WoodmenLife leadership team"
                />
              </picture>
              <div className={'copy'}>
                <WLH2V2>
                  <span className={'overline'}>
                    Leadership{' '}
                    <svg
                      className="triangle"
                      width="7.113"
                      height="8.395"
                      viewBox="0 0 7.113 8.395"
                      role="presentation"
                      aria-hidden="true"
                      tabIndex={-1}
                    >
                      <path
                        className="triangle-path"
                        d="M4.152,1.357a1,1,0,0,1,1.7,0l3.2,5.113A1,1,0,0,1,8.2,8H1.8A1,1,0,0,1,.956,6.47Z"
                        transform="translate(8 -0.803) rotate(90)"
                      ></path>
                    </svg>
                  </span>{' '}
                  Meet Our Leaders
                </WLH2V2>
                <p>
                  WoodmenLife’s leadership team guides our mission to serve
                  members with financial products, strengthen communities and
                  uphold our core values.
                </p>
              </div>
            </a>
          </WLContainer>
          <WLContainer
            as="section"
            className="mav"
            width="EXTRA_WIDE"
            mb={'80px'}
          >
            <WLH2V2>
              <span className="overline">Mission and Values</span> We Are Driven
              by Our Purpose
            </WLH2V2>
            <p className="copy">
              WoodmenLife unites hardworking Americans to secure their financial
              future and strengthen communities. We’re driven by passion for our
              members, service to others, inclusion, collaboration, and
              open-mindedness.
            </p>
          </WLContainer>
          <WLContainer
            as="section"
            width={'EXTRA_WIDE'}
            className="disclosures"
          >
            <b>Disclosures</b>
            <ol>
              <li>
                <p>
                  WoodmenLife Extras are available to members. An individual
                  becomes a member by joining our shared commitment to family,
                  community and country, and by purchasing a WoodmenLife
                  product. These extras are not contractual, are subject to
                  change and have specific eligibility requirements.
                </p>
              </li>
              <br />
              <li>
                <p>
                  Some WoodmenLife Extras include additional membership
                  requirements including length of membership, number of
                  qualifying members in household and/or a qualifying event.
                  WoodmenLife has entered into sponsored marketing relationships
                  with companies that agree to offer discounts to WoodmenLife
                  members. WoodmenLife is not affiliated with these companies
                  and does not administer these discounts for products or
                  services.
                </p>
              </li>
              <br />
              <li>
                <p>
                  Effective Feb. 1, 2024, WoodmenLife is rated A+ Superior by AM
                  Best for our financial strength and operating performance. For
                  the latest Best’s Credit Rating, access{' '}
                  <WLLink to="http://www.ambest.com">www.ambest.com.</WLLink>
                </p>
              </li>
            </ol>
          </WLContainer>
          <Box width="full" maxW="90vw" textAlign="left">
            <PageIdentifier webNumber={'Web 12 R-11/24'} lastUpdated={''} />
          </Box>
        </WLContainer>
      </>
    </Layout>
  );
};

export default AboutPage;
